import { useRouter } from "next/router";

import BlogDetails from "@/components/Blogs/BlogDetails";
import BlogBreadCrumb from "@/components/Common/Blog-BreadCrumb";
import Separator from "@/components/Common/Separator";
import FooterOne from "@/components/Footer/Footer-One";
import HeaderStyleTen from "@/components/Header/HeaderStyle-Ten";
import MobileMenu from "@/components/Header/MobileMenu";
import Cart from "@/components/Header/Offcanvas/Cart";
import Context from "@/context/Context";
import BackToTop from "@/pages/backToTop";
import Store from "@/redux/store";
import Head from "next/head";
import { Provider } from "react-redux";
import BlogData from "../../data/blog/blog.json";


const SingleBlog = () => {
  const router = useRouter();
  const postId = String(router.query.blogId);
  let blogPosts;

  if (postId <= 14) {
    blogPosts = JSON.parse(JSON.stringify(BlogData.blogList));
  } else {
    blogPosts = JSON.parse(JSON.stringify(BlogData.blogGrid));
  }

  const matchedBlog = blogPosts.find((post) => post.id === postId);
  const metaTitleText =  matchedBlog !== undefined ? matchedBlog.metaTitle : ''
  const metaDescText = matchedBlog !== undefined ? matchedBlog.metaDesc : ''
  const metaImg = matchedBlog !== undefined ? matchedBlog.img : ''

  // useEffect(() => {
  //   if (postId && !matchedBlog) {
  //     router.push("/blog-list");
  //   }
  // }, [matchedBlog, router]);

  return (
    <>
      <Head>
        <title>{metaTitleText}</title>
      <meta name="description" content={`${metaDescText}`} />
      <meta property="og:title" content={`${metaTitleText}`} />
        <meta property="og:description" content={`${metaDescText}`} />
        <meta property="og:image" content={metaImg} />
      </Head>
      <Provider store={Store}>
        <Context>
          <MobileMenu />
          <HeaderStyleTen headerSticky="rbt-sticky" headerType="" />
          <Cart />

          <div className="rbt-overlay-page-wrapper padding-top">
            {/* <BlogBreadCrumb matchedBlog={matchedBlog} programDetails={true} /> */}
            <br/><br/>
            <div className="rbt-blog-details-area rbt-section-gapBottom breadcrumb-style-max-width">
              <div className="blog-content-wrapper rbt-article-content-wrapper ps-0 pe-0">
                <BlogDetails
                  matchedBlog={matchedBlog !== undefined ? matchedBlog : ""}
                />
                {/* <div className="related-post pt--60">
                  <div className="section-title text-start mb--40">
                    <span className="subtitle bg-primary-opacity">
                      Related Post
                    </span>
                    <h4 className="title">Similar Post</h4>
                  </div>
                  <BlogListItems
                    selectedBlogs={BlogData.blogList}
                    start={1}
                    end={4}
                  />
                </div> */}
              </div>
            </div>

            {/* <Instagram /> */}
          </div>

          <BackToTop />
          <Separator />
          <FooterOne />
        </Context>
      </Provider>
    </>
  );
};

export default SingleBlog;
