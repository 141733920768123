import Image from "next/image";
import { useEffect, useRef, useState } from "react";

import { FreeMode, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";


import BlogBreadCrumb from "@/components/Common/Blog-BreadCrumb";
import Script from "next/script";
import { HubSpotFormAdvEsth, HubSpotFormClinicalEsth, HubSpotFormESthDip, HubSpotFormMakeUpArt, HubSpotFormMassage, HubSpotFormNailArt } from "../Contacts/Hubspot";

const BlogDetails = ({ matchedBlog, isSlider, isQuote, isAudio, isVideo }) => {
  const thumbsSwiperRef = useRef(null);
  const [activeLink, setActiveLink] = useState(null);
  const links = ["Introduction", "Benefits", "Who Can Enroll", "Certifications Offered", "Curriculum Highlights", "Frequently Asked Questions"];
  const handleLinkClick = (index) => {
    setActiveLink(index); // Save the index of the active link
    const element = document.getElementById(index);
    if (element) {
      const offset = 100; // Adjust this value
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;
  
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedBenefits, setIsExpandedBenefits] = useState(false);
  const [isExpandedCurriculum, setIsExpandedCurriculum] = useState(false);
  const [isExpandedCareer, setIsExpandedCareer] = useState(false);
  const [isRenderBookTour, setIsRenderBookTour] = useState(false)

  useEffect(() => {
    setIsRenderBookTour(true)
    return () => {
      // Anything in here is fired on component unmount.
      setIsRenderBookTour(false)
      console.log('unmount')
  }
  }, [])

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  const toggleReadMoreBenefits = () => {
    setIsExpandedBenefits(!isExpandedBenefits);
  };
  const toggleReadMoreCurriculum = () => {
    setIsExpandedCurriculum(!isExpandedCurriculum);
  };
  const toggleReadMoreCareer= () => {
    setIsExpandedCareer(!isExpandedCareer);
  };

  return (
    <>
      <div className="content">
        {isQuote || isAudio || isVideo ? (
          ""
        ) : (
          <>
            {isSlider ? (
              <div className="post-thumbnail mb--30 position-relative wp-block-image blog-post-gallery-wrapper alignwide">
                <Swiper
                  className="swiper rbt-arrow-between blog-post-gallery-activation"
                  modules={[FreeMode, Navigation]}
                  ref={thumbsSwiperRef}
                  slidesPerView={1}
                  spaceBetween={0}
                  loop={false}
                  autoHeight={true}
                  navigation={{
                    nextEl: ".rbt-arrow-left",
                    prevEl: ".rbt-arrow-right",
                    clickable: true,
                  }}
                >
                  <div className="swiper-wrapper">
                    {matchedBlog &&
                      matchedBlog.gallery.map((item, innerIndex) => (
                        <SwiperSlide className="swiper-slide" key={innerIndex}>
                          <figure>
                            <Image
                              src={item.galleryImg}
                              width={1085}
                              height={645}
                              priority
                              alt="Blog Images"
                            />
                          </figure>
                        </SwiperSlide>
                      ))}
                  </div>
                  <div className="rbt-swiper-arrow rbt-arrow-left">
                    <div className="custom-overfolow">
                      <i className="rbt-icon feather-arrow-left"></i>
                      <i className="rbt-icon-top feather-arrow-left"></i>
                    </div>
                  </div>

                  <div className="rbt-swiper-arrow rbt-arrow-right">
                    <div className="custom-overfolow">
                      <i className="rbt-icon feather-arrow-right"></i>
                      <i className="rbt-icon-top feather-arrow-right"></i>
                    </div>
                  </div>
                </Swiper>
              </div>
            ) : (
              <div className="post-thumbnail position-relative wp-block-image alignwide">
                <figure>
                  {matchedBlog.gallery && (
                    <>
                      <Image
                        src={matchedBlog.gallery[0].galleryImg}
                        width={1085}
                        height={645}
                        priority
                        alt="Blog Images"
                      />
                      <div
                      className="program-title"
                      style={{position: 'absolute', width: '100%', marginTop: '-16rem'}}>
                      <BlogBreadCrumb matchedBlog={matchedBlog} programDetails={true} />
                      </div>
                    </>
                  )}
                  {/* <figcaption>{matchedBlog.caption}</figcaption> */}
                </figure>
              </div>
            )}
          </>
        )}

        {/* {isAudio ? (
          <audio className="mb--40" controls>
            <source
              src="http://axilthemes.com/themes/blogar/wp-content/uploads/2021/01/audio.mp3"
              type="audio/ogg"
            />
            Your browser does not support the audio tag.
          </audio>
        ) : (
          ""
        )} */}

        {isVideo ? (
          <div className="ratio ratio-16x9 alignwide mb--30">
            <iframe
              className="square"
              src="https://www.youtube.com/embed/vlDzYIIOYmM"
              title="YouTube video"
              allowFullScreen
            ></iframe>
          </div>
        ) : (
          ""
        )}
        {/* <div style={{background: "whitesmoke", padding: "30px 50px"}} className="mb--30 text-center">
          <b>Next program start dates:
          Monday, October 7, 2024
          Friday, February 7, 2025</b>
        </div> */}
        <div style={{padding: "30px 50px", marginTop: '2rem'}} >
          <div className="row">
            <div className="col-lg-4 col-sm-12 mb--30">
              <div className="row">
                <div className="col-2 pt-1">
                  <i className="feather-monitor" style={{fontSize: "40px"}}></i>
                </div>
                <div className="col-9">
                  <h5 className="mb-0">Interactive Classroom Environment</h5>
                  <p style={{fontSize: "16px"}}>Instructors ensure students have access to additional support.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 mb--30">
              <div className="row">
                <div className="col-2 pt-1">
                  <i className="feather-award" style={{fontSize: "40px"}}></i>
                </div>
                <div className="col-9">
                  <h5 className="mb-0">Meet Provincial Certification Standards</h5>
                  <p style={{fontSize: "16px"}}>Upon graduation, students can apply to the association to be Registered esthetician diploma.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 mb--30">
              <div className="row">
                <div className="col-2 pt-1">
                  <i className="feather-users" style={{fontSize: "40px"}}></i>
                </div>
                <div className="col-9">
                  <h5 className="mb-0">On-Campus Student Massage Clinic</h5>
                  <p style={{fontSize: "16px"}}>Students get their required hours with first-hand experience on real clients in a real clinic.</p>
                </div>
              </div>
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col-lg-3 col-sm-12 text-center">
              <div style={{background: "black", padding: "30px 30px", borderRadius: "6px"}} className="mb--30">
                <h5 style={{color: 'white'}}>Diploma</h5>
                <p style={{fontSize: '16px', color: 'white'}}>{matchedBlog.title}</p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12 text-center">
              <div style={{background: "black", padding: "30px 30px", borderRadius: "6px"}} className="mb--30">
                <h5 style={{color: 'white'}}>Duration</h5>
                <p style={{fontSize: '16px', color: 'white'}}>
                {
              matchedBlog.id == 'esthetician-diploma' ? `5 Months` : ''
            }
            {
              matchedBlog.id == 'clinical-esthetician' ? '5 Months' : ''
            }
            {
              matchedBlog.id == 'advanced-esthetics-diploma' ? `8 Months` : ''
            }
            {
              matchedBlog.id == 'makeup-artist' ? `4 Months` : ''
            }
            {
              matchedBlog.id == 'nail-technician' ? `4 Months` : ''
            }
            {
              matchedBlog.id == 'massage-therapy' ? `2 Years` : ''
            }
                  
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12 text-center">
              <div style={{background: "black", padding: "30px 30px", borderRadius: "6px"}} className="mb--30">
                <h5 style={{color: 'white'}}>Offered</h5>
                <p style={{fontSize: '16px', color: 'white'}}>On-Site</p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12 text-center">
              <div style={{background: "black", padding: "30px 30px", borderRadius: "6px"}} className="mb--30">
                <h5 style={{color: 'white'}}>Available</h5>
                <p style={{fontSize: '16px', color: 'white'}}>Varying Schedule</p>
              </div>
            </div>
          </div>
        </div>

        <div style={{padding: "50px 50px", background: "whitesmoke"}}>
          <div className="row">
            <div className="col-lg-5 col-sm-12">
              <div style={{backgroundColor: "white", padding: "15px 25px", boxShadow: "0px 6px 34px rgba(215, 216, 222, 0.41)", borderRadius: "6px"}} className="me-3 mb-5">
                <h6>{matchedBlog.title}</h6>
                <h3>Program Overview</h3>
                <a class="rbt-btn radius-round btn-sm hover-transform-non" href="#program-form" style={{color: "white"}}><span data-text="Contact Us">Apply Now</span></a>
                <br/><br/>
                <p>
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    {links.map((link, index) => (
                      <li
                        key={index}
                        onClick={() => handleLinkClick(index)}
                        className={activeLink === index ? "programLink active" : "programLink"}
                      >
                        {/* <a href={`#${index}`}>{link}</a> */}
                        <span>{link}</span>
                        <div className="linkIcon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
                          </svg>
                        </div>
                      </li>
                    ))}
                  </ul>
                </p>
              </div>
            </div>
            <div className="col-lg-7 col-sm-12">
              <h4 id="0">Introduction to the Program</h4>
              <p>{matchedBlog.descOne}</p>
              {/* <blockquote
                className={`${
                  isQuote
                    ? "rbt-blockquote mt--0 alignwide square rbt-border-none bg-color-gray-light"
                    : "wp-block-quote"
                }`}
              >
                <p>{matchedBlog.caption}</p>
                {/* <cite>
                  <Link href="https://gdcollege.ca/">
                    {matchedBlog.name}
                  </Link>
                </cite> 
              </blockquote> */}
              <p>{matchedBlog.descTwo}</p>
              
              {matchedBlog.titleTwo && <h4>{matchedBlog.titleTwo}</h4>}
              {matchedBlog.titleTwo && (
                <p>
                  {matchedBlog.descFour}
                  {/* <Link href="#">{matchedBlog.descFour}</Link> */}
                </p>
              )}

              <h4 id="1">Benefits of Taking an {matchedBlog.title}</h4>
              <p>
                <div className={isExpandedBenefits ? 'row' : 'row certification-section'}>
                  <ul style={{ marginLeft: "20px", marginBottom: '0', listStyle: "none" }}>
                    {matchedBlog &&
                      matchedBlog?.opportunities?.map((item, innerIndex) => (
                        <li key={innerIndex}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                            <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
                          </svg>
                          &nbsp;&nbsp;&nbsp;{item.name}
                        </li>
                      ))}
                  </ul>
                </div>
              </p>
              <div style={{cursor: 'pointer'}}
              className="ms-4" onClick={toggleReadMoreBenefits}>{isExpandedBenefits ? 'Read Less' : 'Read More'} <i className="feather-arrow-right" style={{fontSize: "15px"}}></i></div>
              <br/><br/>

              {matchedBlog?.enroll && <><h4 id="2">Who Can Enroll for this Program</h4>
              <p>
                {matchedBlog?.enroll}
              </p></>}

              {matchedBlog?.certifications?.length > 0 && 
              <><h4 id="3">Certifications Offered after completing the {matchedBlog?.title} at GD College</h4>
              <p className={isExpanded ? 'mb-2' : 'certification-section mb-2'}>
                <div className="row">
                  <ul style={{ marginLeft: "20px", marginBottom: '0', listStyle: "none" }}>
                    {matchedBlog &&
                      matchedBlog?.certifications?.map((item, innerIndex) => (
                        <li key={innerIndex}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                            <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
                          </svg>
                          &nbsp;&nbsp;&nbsp;{item.name}
                        </li>
                      ))}
                  </ul>
                </div>
              </p>
              <div style={{cursor: 'pointer'}}
              className="ms-4" onClick={toggleReadMore}>{isExpanded ? 'Read Less' : 'Read More'} <i className="feather-arrow-right" style={{fontSize: "15px"}}></i></div>
              <br/><br/>
              </>}

              <h4 id="4">Curriculum Highlights</h4>
              <p>{matchedBlog?.curriculumDesc}</p>
                

                  {matchedBlog &&
                      matchedBlog?.curriculum[0]?.list?.length == 0 && 
                      <div className={isExpandedCurriculum ? 'row' : 'row certification-section'}>
                      <ul style={{ marginLeft: "20px", marginBottom: '0', listStyle: "none" }}>
                    {matchedBlog &&
                      matchedBlog?.curriculum?.map((item, innerIndex) => (
                        <li key={innerIndex}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                            <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
                          </svg>
                          &nbsp;&nbsp;&nbsp;{item.title}
                          <ul style={{ marginLeft: "20px", marginBottom: '0', listStyle: "none" }}>
                            {item?.list?.map((item, innerIndex) => (
                                <li key={innerIndex}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
                                  </svg>
                                  &nbsp;&nbsp;&nbsp;{item.name}
                                </li>
                              ))}
                          </ul>
                        </li>
                      ))}
                  </ul></div>}

                  {matchedBlog &&
                      matchedBlog?.curriculum[0]?.list?.length > 0 && 
                      <div className='row'>
                      <div className="rbt-accordion-style rbt-accordion-01  accordion">
                    <div className="accordion" id="accordionExamplea2">
                      {matchedBlog && matchedBlog?.curriculum?.map((item, innerIndex) => (
                        <div className="accordion-item card" key={innerIndex}>
                          <h2
                            className="accordion-header card-header"
                            id={`headingOne${innerIndex}`}
                          >
                            <button
                              className={`accordion-button ${
                                !item.collapsed ? "collapsed" : ""
                              }`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapseOne${innerIndex + 1}`}
                              aria-expanded={item.expand}
                              aria-controls={`collapseOne${innerIndex + 1}`}
                            >
                              {item.title}
                            </button>
                          </h2>
                          <div
                            id={`collapseOne${innerIndex + 1}`}
                            className={`accordion-collapse collapse ${
                              item.show ? "show" : ""
                            }`}
                            aria-labelledby={`headingOne${innerIndex}`}
                            data-bs-parent="#accordionExamplea2"
                          >
                            <div className="accordion-body card-body">
                              <ul style={{ marginLeft: "20px", marginBottom: '0', listStyle: "none" }}>
                                {item?.list?.map((item, innerIndex1) => (
                                    <li key={innerIndex1}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
                                      </svg>
                                      &nbsp;&nbsp;&nbsp;{item.name}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div></div>}

                
                {matchedBlog && matchedBlog?.curriculum[0]?.list?.length == 0 && <div style={{cursor: 'pointer'}}
                  className="ms-4 mt-5" onClick={toggleReadMoreCurriculum}>{isExpandedCurriculum ? 'Read Less' : 'Read More'} <i className="feather-arrow-right" style={{fontSize: "15px"}}></i>
                </div>}
                <p>
              </p>
              {/* {matchedBlog.certifications && (
                <p>
                  <Link href="#">
                    Boost your resume with these certifications after completing this
                    course
                  </Link>
                </p>
              )} */}
              <h4>Career Opportunities for Students After Completing the {matchedBlog.title}</h4>
              <p className={isExpandedCareer ? 'mb-0' : 'mb-0 certification-section'}>
                <div className="row">
                  <ul style={{ marginLeft: "20px", marginBottom: '0', listStyle: "none" }}>
                    {matchedBlog &&
                      matchedBlog?.career?.map((item, innerIndex) => (
                        <li key={innerIndex}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                            <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
                          </svg>
                          &nbsp;&nbsp;&nbsp;{item.name}
                        </li>
                      ))}
                  </ul>
                </div>
              </p>
              <div style={{cursor: 'pointer'}}
                className="ms-4 mt-4" onClick={toggleReadMoreCareer}>{isExpandedCareer? 'Read Less' : 'Read More'} <i className="feather-arrow-right" style={{fontSize: "15px"}}></i></div>
              {/* <p>
                <div className="row">
                  <ol style={{ marginLeft: "20px", marginBottom: '0' }}>
                    {matchedBlog &&
                      matchedBlog?.faqs?.map((item, innerIndex) => (
                        <li key={innerIndex}>
                          <p>
                            <b>{item.qus}</b><br/>
                            {item.ans}
                            {item.list && <ul style={{ marginLeft: "20px", marginBottom: '0' }}>
                              {item.list &&
                                item.list?.map((item, innerIndex) => (
                                  <li key={innerIndex}>
                                    {item.name}
                                  </li>
                                ))}
                            </ul>}
                          </p>
                        </li>
                      ))}
                  </ol>
                </div>
              </p> */}

            </div>
          </div>

        </div>

        <div style={{padding: "30px 50px"}}>
          <h4 id="5">Frequently Asked Questions</h4>
          <div className="rbt-accordion-style rbt-accordion-01  accordion">
            <div className="accordion" id="accordionExamplea1">
              {matchedBlog && matchedBlog.faqs && matchedBlog.faqs.map((item, innerIndex) => (
                <div className="accordion-item card" key={innerIndex}>
                  <h2
                    className="accordion-header card-header"
                    id={`headingTwo${innerIndex}`}
                  >
                    <button
                      className={`accordion-button ${
                        !item.collapsed ? "collapsed" : ""
                      }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapseTwo${innerIndex + 1}`}
                      aria-expanded={item.expand}
                      aria-controls={`collapseTwo${innerIndex + 1}`}
                    >
                      {item.qus}
                    </button>
                  </h2>
                  <div
                    id={`collapseTwo${innerIndex + 1}`}
                    className={`accordion-collapse collapse ${
                      item.show ? "show" : ""
                    }`}
                    aria-labelledby={`headingTwo${innerIndex}`}
                    data-bs-parent="#accordionExamplea1"
                  >
                    <div className="accordion-body card-body">
                      {item.ans}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div style={{padding: "50px 50px 70px", backgroundColor: 'black'}} className="mb--30">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h4 style={{color: 'white'}}>{matchedBlog.title} Information Kit</h4>
              <p style={{color: 'white'}}>This kit includes important details on the {matchedBlog.title} program at GD College.</p>
            </div>
          </div>
          <br/>
          <div className="row" id="program-form">
            {
              matchedBlog.id == 'esthetician-diploma' ? <HubSpotFormESthDip /> : ''
            }
            {
              matchedBlog.id == 'clinical-esthetician' ? <HubSpotFormClinicalEsth /> : ''
            }
            {
              matchedBlog.id == 'advanced-esthetics-diploma' ? <HubSpotFormAdvEsth /> : ''
            }
            {
              matchedBlog.id == 'makeup-artist' ? <HubSpotFormMakeUpArt /> : ''
            }
            {
              matchedBlog.id == 'nail-technician' ? <HubSpotFormNailArt /> : ''
            }
            {
              matchedBlog.id == 'massage-therapy' ? <HubSpotFormMassage /> : ''
            }
            
            {/* <div className="col-lg-6">
              <label for="First-Name-2" className="form-label" style={{color: 'white'}}>First Name *</label>
              <input style={{backgroundColor: 'white'}} class="form-field w-input" maxlength="256" name="First-Name" data-name="First Name" placeholder="First Name" type="text" id="First-Name-2" required=""></input>
            </div>
            <div className="col-lg-6">
              <label for="First-Name-2" className="form-label" style={{color: 'white'}}>Last Name *</label>
              <input style={{backgroundColor: 'white'}} class="form-field w-input" maxlength="256" name="Last-Name" data-name="Last Name" placeholder="Last Name" type="text" id="Last-Name-2" required=""></input>
            </div>
            <div className="col-lg-12 mt-5">
              <label for="First-Name-2" className="form-label" style={{color: 'white'}}>Email Address *</label>
              <input style={{backgroundColor: 'white'}} class="form-field w-input" maxlength="256" name="Last-Name" data-name="Last Name" placeholder="Email Address" type="text" id="Last-Name-2" required=""></input>
            </div> */}
          </div>
        </div>

        <div style={{padding: "30px 50px"}} className="mb--30">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h4>Book a Tour</h4>
    {isRenderBookTour ? <><div class="meetings-iframe-container" data-src="https://meetings.hubspot.com/gdcollege/tour?embed=true"></div>
    <Script type="text/javascript" src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"></Script></> : ''}
  {/* <Image src={tour} property="true" alt="Gallery Images" /> */}
                      
                {/* <div class="meetings-iframe-container" data-src="https://meetings.hubspot.com/gdcollege/tour?embed=true"></div>
                <script type="text/javascript" src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"></script> */}
                
                {/* Esthetician Diploma - <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
                <script>
                  hbspt.forms.create({
                    region: "na1",
                    portalId: "46151403",
                    formId: "e19b6baf-e97a-4496-85da-46333336e874"
                  });
                </script> */}

            </div>
          </div>
        </div>

        
        {/* <div className="wp-block-gallery columns-3 is-cropped">
          <ul className="blocks-gallery-grid">
            {matchedBlog &&
              matchedBlog.gallery.map((item, innerIndex) => (
                <li className="blocks-gallery-item" key={innerIndex}>
                  <figure>
                    {item.galleryImg && (
                      <Image
                        className="radius-4"
                        src={item.galleryImg}
                        width={255}
                        height={143}
                        priority
                        alt="Blog Images"
                      />
                    )}
                  </figure>
                </li>
              ))}
          </ul>
        </div> */}

        {/* <h4>{matchedBlog.title}</h4> */}

        

        {/* <p>{matchedBlog.descThree}</p>

        <h4>{matchedBlog.titleTwo}</h4>

        <p>
          <Link href="#">{matchedBlog.linkTwo}</Link> {matchedBlog.descSaven}
        </p> */}

        

        {/* <p>{matchedBlog.descThree}</p> */}
        
        {/* <div className="row">
          {matchedBlog?.objectives &&
            matchedBlog.objectives.map((item, innerIndex) => (
              <div className="col-lg-4" key={innerIndex}>
                <ul className="rbt-sidebar-list-wrapper recent-post-list">
                  <li style={{ listStyle: "none" }}>
                    <div className="content">
                      <h6 className="title">
                        <i className="feather-check-circle"></i> {item.name}
                      </h6>
                    </div>
                  </li>
                </ul>
              </div>
            ))} */}
          {/* <div className="col-lg-4">
            <ul className="rbt-sidebar-list-wrapper recent-post-list">
              <li style={{'listStyle':'none'}}>
                <div className="content">
                  <h6 className="title"><i className="feather-check-circle"></i> Students will be able to provide Skincare Consultations and Specialty Skincare Treatments</h6>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-4">
            <ul className="rbt-sidebar-list-wrapper recent-post-list">
              <li style={{'listStyle':'none'}}>
                <div className="content">
                  <h6 className="title"><i className="feather-check-circle"></i> Students will master their skill set in Makeup Artistry and Hairstyling</h6>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-4">
            <ul className="rbt-sidebar-list-wrapper recent-post-list">
              <li style={{'listStyle':'none'}}>
                <div className="content">
                  <h6 className="title"><i className="feather-check-circle"></i> Students will be able to use a range of specialized equipment, which complies with national, provincial, and other industry regulated standards</h6>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-4">
            <ul className="rbt-sidebar-list-wrapper recent-post-list">
              <li style={{'listStyle':'none'}}>
                <div className="content">
                  <h6 className="title"><i className="feather-check-circle"></i> Students will be able to follow all Infection Control Provincial Standards</h6>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-4">
            <ul className="rbt-sidebar-list-wrapper recent-post-list">
              <li style={{'listStyle':'none'}}>
                <div className="content">
                  <h6 className="title"><i className="feather-check-circle"></i> Students will be able to Identify Business and Marketing Skills required to start a small business or work at a salon or spa</h6>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-12">
            <ul className="rbt-sidebar-list-wrapper recent-post-list">
              <li style={{'listStyle':'none'}}>
                <div className="content">
                  <h6 className="title"><i className="feather-check-circle"></i> Students will graduate with the knowledge and understanding to apply all relevant skills to provide the best services</h6>
                </div>
              </li>
            </ul>
          </div> */}
        {/* </div> */}

        {/* {matchedBlog.titleTwo && (
          <div className="post-thumbnail mb--30 position-relative wp-block-image alignwide">
            <figure>
              {matchedBlog.gallery && (
                <Image
                  src={matchedBlog.gallery[2].galleryImg}
                  width={1085}
                  height={645}
                  priority
                  alt="Blog Images"
                />
              )}
            </figure>
          </div>
        )} */}

        
       

        
        {/* <p>
          <div className="row">
            {matchedBlog?.curriculum &&
              matchedBlog.curriculum.map((item, innerIndex) => (
                <div className="col-4 mb-5" key={innerIndex}>
                  <h5>{item.title}</h5>
                  <ul style={{ marginBottom: "0" }}>
                    {item?.list &&
                      item.list.map((item1, innerIndex1) => (
                        <li key={innerIndex1}>{item1.name}</li>
                      ))}
                  </ul>
                </div>
              ))}
          </div>
        </p> */}
        {/* <div className="post-thumbnail mb--30 position-relative wp-block-image alignwide">
          <figure>
            {matchedBlog.gallery && (
              <Image
                src={matchedBlog.gallery[3].galleryImg}
                width={1085}
                height={645}
                priority
                alt="Blog Images"
              />
            )}
            {/* <figcaption>{matchedBlog.caption}</figcaption> 
          </figure>
        </div> */}
        {/* <h4>{matchedBlog.titleFive}</h4>
        <p>
          <div className="row">
            {matchedBlog?.opportunities &&
              matchedBlog.opportunities.map((item, innerIndex) => (
                <div className="col-6 " key={innerIndex}>
                  <ul style={{ marginBottom: "0" }}>
                    <li>{item.name}</li>
                  </ul>
                </div>
              ))}
          </div>
        </p>
        <blockquote
          className={`${
            isQuote
              ? "rbt-blockquote mt--0 alignwide square rbt-border-none bg-color-gray-light"
              : "wp-block-quote"
          }`}
        >
          <p>Loan and Finance Options</p>
          <cite>
            <Link href="#">
              Government Grants, Student Loans & Self Pay Option with no
              Additional Fees, Alberta Student Aid, 19 years or older with all
              provinces with the exception of Alberta, GED or High School
              Diploma.
            </Link>
          </cite>
          {/* <a class="rbt-btn rbt-marquee-btn marquee-auto btn-border-gradient radius-round btn-sm hover-transform-none mt-4" href="/blog-with-sidebar#"><span data-text="Enroll Now">Enroll Now</span></a> *
        </blockquote>
        {/* <div className="tagcloud">
          {matchedBlog &&
            matchedBlog.tag.map((tagItem, innerIndex) => (
              <Link href="#" key={innerIndex}>
                {tagItem.tag}
              </Link>
            ))}
        </div> */}

        {/* <div className="social-share-block">
          <div className="post-like">
            <Link href="#">
              <i className="feather feather-thumbs-up"></i>
              <span>2.2k Like</span>
            </Link>
          </div>
          <ul className="social-icon social-default transparent-with-border">
            {matchedBlog &&
              matchedBlog.social.map((socialItem, innerIndex) => (
                <li key={innerIndex}>
                  <Link href={socialItem.url}>
                    <i className={socialItem.icon}></i>
                  </Link>
                </li>
              ))}
          </ul>
        </div> */}

        {/* <div className="about-author">
          {matchedBlog &&
            matchedBlog.author.map((author, innerIndex) => (
              <BlogAuthor {...author} author={author} key={innerIndex} />
            ))}
        </div>

        <div className="rbt-comment-area">
          <div className="rbt-total-comment-post">
            <div className="title">
              <h4 className="mb--0">30+ Comments</h4>
            </div>
            <div className="add-comment-button">
              <a
                className="rbt-btn btn-gradient icon-hover radius-round btn-md"
                href="#"
              >
                <span className="btn-text">Add Your Comment</span>
                <span className="btn-icon">
                  <i className="feather-arrow-right"></i>
                </span>
              </a>
            </div>
          </div>
          <ComntForm />
        </div>
        <div className="rbt-comment-area">
          <h4 className="title">2 comments</h4>
          <ul className="comment-list">
            {matchedBlog &&
              matchedBlog.comment.map((comnt, innerIndex) => (
                <Comment {...comnt} comnt={comnt} key={innerIndex} />
              ))}
          </ul>
        </div> */}
      </div>
    </>
  );
};

export default BlogDetails;
